@import 'src/styles/variables'

$border-color: #F7F7F7

.header-wrapper
	position: fixed
	width: 100vw
	top: 0
	left: 0
	z-index: 9
	transition: .3s

	&.primary
		&.scroll
			background: $blue
			border-bottom: 1px solid $blue
			box-shadow: 5px 0 5px #53474745

			.header
				height: 80px
				&__nav
					margin-bottom: 20px


.primary
	.header
		display: flex
		align-items: center
		height: 80px
		width: 100%
		padding: 10px 0
		transition: .3s
		justify-content: space-between
		box-sizing: border-box
		position: relative
		@media (min-width: $xl)
			height: 130px
			align-items: flex-end
			padding-right: 140px

		&__logo
			display: flex
			margin-bottom: 7px 
			@media (max-width: $sm)
				margin-bottom: 0px 
			img
				width: 56px
				height: 51px
				@media (min-width: $xl)
					width: 100%
					height: 100%

		&__burger-menu
			display: flex
			align-items: center

		&__nav
			display: flex
			align-items: flex-end
			margin-left: 50px
			margin-bottom: 10px
			@media (min-width: $xxl)
				margin-left: 50px

			& > li
				cursor: pointer

				&:not(:last-child)
					margin-right: 20px

					@media (min-width: $xxl)
						margin-right: 46px

		&__languages
			margin-left: 46px


.burger-menu
	background: $white
	width: 100%
	height: 100%
	padding: 15px
	position: fixed
	left: 0
	right: 0
	bottom: 0
	top: -120%
	z-index: 999
	transition: top 0.4s cubic-bezier(1, 0, 0, 1)
	overflow-y: auto
	display: flex
	flex-direction: column

	&.open
		top: 0

	&__header
		display: flex
		justify-content: space-between
		align-items: center

	&__nav
		margin-top: 30px
		text-align: center

		li
			&:not(:last-child)
				margin-bottom: 25px

			a
				span
					font-size: 20px
					line-height: 20px

	&__img
		display: flex
		justify-content: center
		margin-top: 24px

	&__footer
		border-top: 1px solid $deep-black
		text-align: center
		font-size: 12px
		line-height: 16px
		margin-top: 24px
		padding: 24px 0 15px

		.created-by
			display: flex
			align-items: center
			justify-content: center
			margin-top: 8px
			img
				margin-bottom: 5px
				margin-right: 5px


.categories
	&.header-wrapper
		background: $white

	.header
		display: flex
		align-items: center
		height: 76px
		padding-left: 10px
		&__logo
			img
				width: 67px
				height: 61px

		&__location
			padding: 0 20px
			margin-left: 43px
			border-left: 2px solid $border-color
			border-right: 2px solid $border-color
			position: relative
			height: 100%

			&-icon
				display: flex
				align-items: center
				justify-content: center
				flex-direction: column
				height: 100%
				cursor: pointer

			&-wrapper
				position: absolute
				top: 76px
				left: 0
				width: 858px
				height: 555px
				@media (min-width: $xxl)
					width: 1066px

		&__search
			flex: 1
			height: 100%

			&.active
				display: flex
				align-items: flex-end
				padding-bottom: 14px
				border-right: 2px solid $border-color

			.hidden
				display: flex
				justify-content: flex-end
				height: 100%

				.search
					display: flex
					align-items: center
					height: 100%
					padding: 0 20px
					border-left: 2px solid $border-color
					border-right: 2px solid $border-color
					cursor: pointer

				span
					margin-left: 10px

			.show
				width: 100%
				position: relative

				.close
					position: absolute
					right: 20px
					top: 6px
					z-index: 99

		&__partner-button
			margin-left: 14px


.categories-mobile
	background: $white
	border-bottom: 2px solid #F7F7F7

	.top
		display: flex
		justify-content: space-between
		align-items: flex-end
		width: 100%

	.header
		padding-top: 15px
		padding-bottom: 8px

		&__search
			width: 100%
			padding-right: 10px

		&__location
			max-width: 49px
			width: 100%
			height: 40px
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
			border-radius: 5px
			display: flex
			align-items: center
			justify-content: center

		&__partner-button
			opacity: 0
			height: 0
			transition: .3s ease-in-out
			&.active
				margin-top: 32px
				margin-bottom: 7px
				opacity: 1
				height: auto
				transition: .3s ease-in-out

		&__open
			position: absolute
			right: 0
			bottom: -36px
			background: $white
			width: 56px
			height: 34px
			border-radius: 0 0 5px 5px
			display: flex
			align-items: center
			justify-content: center


.locswitch
	position: absolute
	top: 75px
	right: 0%
	transition: 0.3s