@import 'src/styles/variables'

.carousel
	position: relative
	> div > div
		&:first-child
			@media (min-width: $xl)
				margin-left: -50px


.navigation
	display: flex
	justify-content: center
	margin-top: 40px
	margin-bottom: 30px
	width: 200px
	margin-left: auto
	margin-right: auto

	.nav-button
		width: 50px
		height: 50px
		background: $white
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.2)
		border-radius: 5px
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
		margin: 0 15px
