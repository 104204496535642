@import 'src/styles/variables'

$border-color: #D7DEF0
$icon-color: #1A0F91


.panel
	border-bottom: 3px solid $border-color
	padding: 25.5px 0
	@media (min-width: $xl)
		padding: 25.5px 0 27.5px

	&:first-child
		border-top: 3px solid $border-color

	&.active
		.panel__content
			visibility: visible
			opacity: 1

		.panel__options
			&:before
				transform: rotate(0deg)

			> .arrow
				font-size: 20px
				transform: rotate(-45deg)

	&__label
		position: relative
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%
		text-align: left
		font-weight: 700
		font-size: 14px
		font-family: inherit
		transition: color 0.2s linear
		cursor: pointer
		z-index: 1
		color: $deep-black

		&:disabled
			color: $gray

	&__options
		display: flex
		align-items: center
		margin-left: auto

		.arrow
			margin: 0 7px
			transition: all 0.3s ease-in-out
			font-size: 15px
			color: $icon-color

	&__additing-options
		display: flex

		i
			margin-left: 7px

	&__inner
		overflow: hidden
		will-change: height
		transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1)

	&__content
		color: black
		opacity: 0
		visibility: hidden
		transition: opacity 0.3s linear 0.18s
		padding-top: 16px
		font-weight: 500
		@media (min-width: $xl)
			padding-top: 18px
