@import 'src/styles/variables'
@import 'src/styles/animations'

.primary
	&__zumzak-logo
		display: flex
		justify-content: center

	&__become-partner
		display: flex
		justify-content: center
		@media (min-width: $xl)
			justify-content: flex-start

	&__store-wrapper
		z-index: 1
		position: relative
		transform: translate(20px, 0px)
		@media (min-width: $xxxl)
			transform: translate(40px, -80px)

		.store
			width: 100%
			height: auto
			@media (min-width: $xxl)
				width: 120%
			@media (min-width: $xxxl)
				width: auto

		.location
			position: absolute
			animation: jump 4s ease-out infinite
			width: 18%
			height: auto
			@media (min-width: $xxl)
				width: auto

			&.left
				left: 14%
				top: 36%

			&.right
				top: 44%
				right: 50px
				@media (min-width: $md)
					right: 90px
				@media (min-width: $xxl)
					right: 0

		.cloud
			position: absolute
			z-index: -1
			animation: cloud 6s ease-in-out infinite
			width: 35%
			height: auto
			@media (min-width: $xxl)
				width: auto

			&.left
				top: 30px
				left: 30px
				@media (min-width: $xxl)
					top: 100px
					left: 50px

			&.right
				top: 90px
				right: 10px
				animation-duration: 9s
				@media (min-width: $xxl)
					top: 200px
					right: -120px

			&.top
				top: 0
				left: 50%
				animation-duration: 7s
				@media (min-width: $xxl)
					top: 0
					left: 60%

	&__store-background
		position: absolute
		top: -200px
		width: 120%
		right: 0
		@media (min-width: $sm)
			right: -70px
		@media (min-width: $md)
			top: -350px
			right: -150px
		@media (min-width: $xl)
			top: -290px

		img
			width: 100%
			height: auto


.ball
	position: absolute
	animation: circle 7s linear infinite

	img
		width: 100%
		height: 100%
		object-fit: contain

	&.small
		top: 63%
		left: 25.5%
		width: 81px
		height: 81px
		filter: drop-shadow(-15px 0px 13px rgba(0, 0, 0, 0.45))
		animation-duration: 10s

	&.medium
		top: 38.5%
		right: 32%
		width: 133px
		height: 133px
		filter: drop-shadow(-30px 5px 20px rgba(0, 0, 0, 0.45))

	&.big
		top: 24.5%
		right: -135px
		width: 227px
		height: 227px
		filter: drop-shadow(20px 55px 40px rgba(0, 0, 0, 0.45))
		animation-duration: 13s

	&.white
		bottom: 325px
		left: -140px
		animation-duration: 15s

	&.mobile
		z-index: 1
		width: 94px
		height: 94px
		filter: drop-shadow(5px 20px 10px rgba(0, 0, 0, 0.45))
