@import 'src/styles/variables'

.about
	&__advertising-video
		background: $sand
		border-radius: 30px
		width: 100%
		height: 216px
		display: flex
		align-items: center
		justify-content: center
		@media (min-width: $md)
			border-radius: 37px
			height: 432px

		.circle
			width: 77px
			height: 77px
			border: 2px solid $gray-light
			border-radius: 50%
			display: flex
			align-items: center
			justify-content: center
			cursor: pointer
			padding-left: 3px
