@import 'src/styles/variables'
@import 'src/styles/animations'

.banner-title
	font-size: 30px
	line-height: 36px
	margin-top: 30px
	@media (min-width: $xl)
		margin-top: 45px
		font-size: 40px
		line-height: 49px

.pointer-goods
	position: relative
	z-index: 1


.products
	top: 120px
	left: 5px

	.product
		position: absolute
		transition: .3s all

		&:hover
			transform: scale(1.04)
			filter: drop-shadow(0 0 10px $white)

		&--0
			top: 25px
			left: 4px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(140deg)

		&--1
			top: 72px
			left: 12px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(100deg)

		&--2
			top: 116px
			left: 73px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(70deg)

		&--3
			top: 110px
			left: 142px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(30deg)

		&--4
			top: 82px
			left: 192px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(0deg)

	.semicircle
		position: absolute
		left: 89px
		top: 42px

	.circle
		max-width: 104px
		width: 100%
		height: auto

	.pointer
		position: absolute
		left: 17%
		top: -4px
		transition: .3s ease-out

		&-wrapper
			position: absolute
			left: 97px
			top: 5px

		&-container
			position: relative


.technicals
	width: 100%
	height: 100%
	top: -50px
	right: 90px
	min-height: 175px
	@media (min-width: $xs)
		right: 40px
	@media (min-width: $xl)
		right: auto
		top: -36px
		min-height: 305px
		left: -40px

	.technical
		position: absolute
		transition: .3s all

		img
			width: calc(10vh + 5px)
			@media (min-width: $xl)
				width: initial

		&:hover
			transform: scale(1.04)
			filter: drop-shadow(0 0 10px $white)

		&--0
			top: 55px
			left: 127px
			@media (min-width: $xl)
				top: 40px
				left: auto

			img
				width: calc(10vh + -8px)
				@media (min-width: $xl)
					width: initial

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(50deg)

		&--1
			top: 98px
			left: 135px
			@media (min-width: $xl)
				top: 131px
				left: 18px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(15deg)

		&--2
			top: 139px
			left: 191px
			@media (min-width: $xl)
				top: 220px
				left: 140px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(-15deg)

		&--3
			top: 135px
			left: 257px
			@media (min-width: $xl)
				top: 210px
				left: 280px

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(-55deg)

		&--4
			top: 109px
			left: 303px
			@media (min-width: $xl)
				top: 152px
				left: 380px

			img
				width: calc(10vh + -8px)
				@media (min-width: $xl)
					width: initial

			&:hover
				~ .pointer-wrapper
					.pointer
						transform: rotate(-90deg)

	.semicircle
		position: absolute
		left: 205px
		top: 70px
		width: 110px
		height: auto
		@media (min-width: $xl)
			left: 170px
			top: 70px
			width: initial

	.circle
		width: 96px
		height: auto
		@media (min-width: $xl)
			width: initial

	.pointer
		position: absolute
		left: 17%
		top: 11%
		transition: .3s ease-out
		width: 70px
		height: auto
		@media (min-width: $xl)
			width: initial

		&-wrapper
			position: absolute
			left: 215px
			top: 40px
			@media (min-width: $xl)
				left: 190px
				top: 0

		&-container
			position: relative


.blot-small
	background-image: url("/assets/categories/blot-small.svg")
	background-repeat: no-repeat
	background-size: contain
	position: absolute
	height: 217px
	width: 123px
	bottom: -130px
	left: -55px
	@media (min-width: $xxxl)
		width: 193px
		bottom: -100px
		left: 0

.blot-large
	background-image: url("/assets/categories/blot-large.svg")
	background-repeat: no-repeat
	background-size: contain
	min-height: 110%
	width: 361px
	position: absolute
	right: 0
	bottom: -5px


.ball
	position: absolute
	animation: circle 10s linear infinite

	img
		width: 100%
		height: 100%
		object-fit: contain

	&.big
		width: 85px
		height: 85px
		filter: drop-shadow(5px 15px 15px rgb(0 0 0 / 50%))
		@media (min-width: $xl)
			width: 130px
			height: 130px

	&.medium
		width: 109px
		height: 109px
		filter: drop-shadow(5px 15px 15px rgb(0 0 0 / 50%))
		animation-duration: 12s

	&.small
		width: 51px
		height: 51px
		filter: drop-shadow(-10px 0px 10px rgba(0, 0, 0, 0.5))
		animation-duration: 14s
