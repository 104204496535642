@import 'src/styles/variables'

.footer
	padding: 37px 0 50px
	position: relative
	z-index: 1
	background: #292E34
	@media (min-width: $xl)
		padding: 75px 0 60px

	&-wrapper
		position: relative

	&__top
		display: flex
		align-items: center
		padding-bottom: 16px
		border-bottom: 2px solid $white
		flex-direction: column
		@media (min-width: $xl)
			flex-direction: row
			align-items: flex-end
			padding-bottom: 28px

		.left, .right
			@media (min-width: $xl)
				width: 230px

		.center
			display: flex
			justify-content: center
			flex-direction: column
			flex: 1
			@media (min-width: $xl)
				flex-direction: row

			ul
				li
					color: $white
					font-size: 12px
					line-height: 18px

					&:not(:last-child)
						margin-bottom: 10px

			.number
				margin-top: 20px
				text-align: center

				li
					&:not(:last-child)
						margin-bottom: 0

			.menu
				margin-top: 27px
				text-align: center

				li
					&:not(:last-child)
						margin-bottom: 16px

		.right
			display: flex
			justify-content: flex-end

	&__bottom
		margin-top: 18px
		display: flex
		align-items: center
		justify-content: space-between
		flex-direction: column
		@media (min-width: $xl)
			flex-direction: row

		.left
			font-size: 12px
			line-height: 14px
			font-weight: 300
			color: $white
			text-align: center

		.right
			.created-by
				display: flex
				align-items: center
				margin-top: 10px
				@media (min-width: $xl)
					margin-top: 0

				img
					margin: 0 5px 8px

	.button-top
		width: 35px
		height: 35px
		border-radius: 50%
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
		background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
		position: absolute
		top: 22px
		right: 20px
		@media (min-width: $xl)
			width: 40px
			height: 40px
			border: 3px solid white
			padding-bottom: 5px
			background: none
			position: static


.categories
	z-index: 1

	.footer
		background: #0A84FF
		@media (min-width: $xl)
			background: none

		&__top
			display: flex

			.left
				display: flex
				flex-direction: column
				align-items: center
				@media (min-width: $xl)
					align-items: flex-start

			.center
				.get-eshop
					margin-top: 40px
					display: flex
					align-items: center
					flex-direction: column
					position: relative

					&__arrow
						position: absolute
						top: 20%
						left: 67%

	.become-partner
		padding: 10px 30px
		border: 2px solid $white
		margin-top: 50px
		background: none
		@media (min-width: $xl)
			margin-top: 35px
			padding: 10px

		&:hover
			box-shadow: 0 0 10px $white

		&__link
			width: 100%


	.button-top
		border: 3px solid white
