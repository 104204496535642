@import 'src/styles/variables'
@import 'src/styles/animations'

.join-us
	background: $blue-light
	padding: 15px 15px 70px
	position: relative
	border-radius: 5px
	display: flex
	flex-direction: column
	@media (min-width: $md)
		align-items: center
	@media (min-width: $xl)
		height: 210px
		padding: 0 68px
		flex-direction: row

	&__call-to-action
		display: flex
		align-items: center
		@media (min-width: $xl)
			margin-right: 260px
			flex-direction: column
			justify-content: center
		@media (min-width: $xxl)
			flex-direction: row

		button
			margin-left: 30px
			@media (min-width: $xl)
				margin-top: 20px
			@media (min-width: $xxl)
				margin-top: 0

	&__girl-wrapper
		position: relative
		flex: 1
		right: 0
		bottom: 0
		z-index: 1
		padding-bottom: 5px
		margin-left: auto
		margin-right: auto
		margin-top: 20px
		@media (min-width: $sm)
			margin-top: 20px
		@media (min-width: $xl)
			align-self: flex-end

		.girl
			bottom: -7px
			right: -88px
			max-width: 354px
			width: 100%
			@media (min-width: $xl)
				position: absolute
				margin-top: 0
				width: auto

		.girl-leg
			position: absolute
			right: 51%
			bottom: 15%
			transform: rotate(0)
			animation: girl-leg 2s ease-in-out infinite
			@media (min-width: $xl)
				right: 95px
				bottom: 18px

		.heart
			position: absolute
			left: 45px
			top: 60px
			animation: cloud 3s ease-in-out infinite

		.branch
			position: absolute
			animation: branch 5s ease infinite
			z-index: -1
			bottom: 45px
			right: 25px
			@media (min-width: $xl)
				bottom: 33px
				right: -55px

	&__ruble
		position: absolute
		animation: jump 2s ease infinite

		&.left
			top: 25px
			left: 30px

		&.right
			top: 155px
			right: 15px
			max-width: 20px
			z-index: 1
			@media (min-width: $xl)
				top: 63px
				right: -12px

	&__branch
		position: absolute

		&.left
			bottom: -2px
			left: -20px
			@media (min-width: $xl)
				left: -40px

		&.right
			animation: branch 7s ease infinite
			top: 98px
			left: -20px
			@media (min-width: $xl)
				top: auto
				left: auto
				bottom: -2px
				right: -30px


@keyframes branch
	50%
		transform: scale(1.1) translate(5px, -10px)

@keyframes girl-leg
	50%
		transform: rotate(-12deg) translate(2px, 4px)
