@import 'src/styles/variables'

.icon-wrapper
	display: flex
	align-items: center
	justify-content: center

	&.position-relative
		position: relative


.primary
	color: $deep-black

.white
	color: $white

.gray
	color: $gray

.blue
	color: $blue

.gray-light
	color: $gray-light


.xxl
	font-size: 49px
	@media (min-width: $xl)
		font-size: 66px

.xl
	font-size: 38px

.lg
	font-size: 32px

.md
	font-size: 28px

.sm
	font-size: 24px

.xs
	font-size: 20px


.turn-off
	position: relative

	&::after
		content: ''
		position: absolute
		background: $deep-black
		height: 1px
		transform: rotate(45deg)


	&.md
		&::after
			width: 25px
			top: 11px
			right: -1px


.hover
	cursor: pointer
	transition: .2s

	&.hover-gray
		&:hover
			color: $gray


.pointer
	cursor: pointer
