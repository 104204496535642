@import 'variables'

h1
	font-size: 57px
	line-height: 69px

h2
	font-size: 28px
	line-height: 34px
	@media (min-width: $md)
		font-size: 30px
		line-height: 37px
