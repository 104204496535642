@import 'src/styles/variables'

.background
	background-image: url("/assets/index/out-store-background.svg")
	height: 560px
	transform: translate(-50%, -40%)
	left: 50%
	top: 50%
	bottom: 0
	width: 100%
	position: absolute
	background-repeat: no-repeat
	background-size: cover
	@media (min-width: $md)
		height: 700px
	@media (min-width: $lg)
		height: 900px
	@media (min-width: $xl)
		height: 1100px
		transform: translate(-50%, -45%)
	@media (min-width: $xxxl)
		height: 1350px

.carousel-wrapper
	padding: 0 15px 50px
	@media (min-width: $xl)
		padding: 0


.all-stores
	position: absolute
	z-index: -1
	bottom: -20px
	left: 20%
	width: 100%
	@media (min-width: $xl)
		bottom: 30px
		left: auto
