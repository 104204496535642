@import 'src/styles/variables'

.select-categories
	position: relative
	box-shadow: 0 3.42735px 60px rgba(20, 0, 79, 0.08)

	&__background
		background: $white
		position: absolute
		height: calc(100% + 450px)
		width: 100vw
		top: -20px
		right: 0
		border-radius: 0 0 37px 0


.filter-categories
	z-index: 1
	position: relative

	&__list
		margin: 10px 0 0 -20px

	&__item
		display: flex
		align-items: center
		padding: 11px 0 11px 20px
		cursor: pointer
		transition: .3s
		position: relative
		@media (min-width: $xl)
			&:hover
				background: #0094e780

				span, i
					color: $white

		&:before
			content: ''
			position: absolute
			width: 90%
			height: 2px
			background: #F5F9FB
			bottom: -2px

		&:not(:last-child)
			margin-bottom: 2px

		&.active
			background: #0094e780

			span, i
				color: $white

	&__reset-button
		margin-top: 10px

	&.mobile
		.filter-categories__list
			margin: 10px -15px 50px -15px

		.filter-categories__reset-button
			position: fixed
			bottom: 0
			right: 0
			height: 56px
			text-align: center
			background: $white
			width: 100%
			display: flex
			align-items: center
			justify-content: center
			box-shadow: 0 3.42735px 51.4103px rgb(20 0 79 / 10%)
			@media (min-width: 700px)
				width: 500px


.stores-list
	&__group
		&:not(:last-child)
			margin-bottom: 20px

		.title
			font-size: 18px
			line-height: 22px
			margin-top: 10px
			@media (min-width: $xl)
				margin-top: 0
				font-size: 22px
				line-height: 27px


.filter-categories-mobile
	&__icon-open
		position: fixed
		bottom: 20px
		right: 20px
		background: $blue
		width: 49px
		height: 49px
		border-radius: 50%
		display: flex
		align-items: center
		justify-content: center


.control-menu
	&__header
		position: relative

		.icon-close
			position: absolute
			top: 10px
			right: 0
			z-index: 999

		.search
			padding-right: 40px

	&__content
		margin-top: 20px
