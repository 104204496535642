$deep-black: #000000
$white: #ffffff
$blue: #0094E7
$blue-light: #78C2DD
$blue-dark: #456580
$gray: #8d8d8d
$gray-light: #C0C0C0
$sand: #EFEFEF
$shadow-primary: 0 0 5px rgba(0, 0, 0, 0.2)

$font-primary: 'Montserrat', sans-serif

$xs: 375px
$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1400px
$xxxl: 1800px
