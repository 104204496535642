@keyframes circle
	0%
		transform: rotate(0deg) translate(-20px) rotate(0deg)

	100%
		transform: rotate(360deg) translate(-20px) rotate(-360deg)

@keyframes jump
	50%
		transform: translateY(-20%)

@keyframes cloud
	50%
		transform: translate3d(20%, 0, 0)

