@import 'src/styles/variables'

.form-input-wrapper
	position: relative
	display: inline-block

	.errorMsg
		animation: animateError 0.3s ease 0s 1 normal none

	.label
		display: block
		margin-bottom: 10px

	&.error
		//.input, &.select > div
		//	border-color: $danger
		//	box-shadow: $shadow-danger
		//
		//.label
		//	color: $danger

	&.isFluid
		width: 100%

		.input-container,
		.input
			width: 100%

	&.search
		.input
			padding: 5px 10px

	&.checkbox
		cursor: pointer
		display: flex


.input-container
	position: relative
	display: inline-flex

	.prefix
		position: absolute
		left: 0
		top: 0
		bottom: 0
		display: flex
		align-items: center
		justify-content: center
		transition: color 0.3s ease

		i
			color: $gray

	.suffix
		position: absolute
		right: 0
		top: 0
		bottom: 0
		display: -moz-box
		display: flex
		align-items: center
		justify-content: center
		transition: all 0.3s ease

		i
			color: $gray

		span + i
			margin-left: 5px


	.toggle-password
		cursor: pointer
		display: inline-flex

		i
			&::after
				background: $gray

.input
	background: $white
	line-height: 15px
	transition: all 0.3s ease
	appearance: none
	outline: none
	border: 1px solid transparent
	padding: 10px
	font-size: 16px
	font-family: $font-primary
	font-weight: 600
	resize: none
	height: 40px
	border-radius: 0
	@media (min-width: $md)
		font-size: 18px

	&-group
		display: flex

		&.border-group
			border: 1px solid $gray-light
			border-radius: 5px
			padding: 2px

		.form-input-wrapper
			flex: 1 1 auto

	&-group-item
		display: flex
		align-items: center
		text-align: center
		white-space: nowrap

	&.hasPrefix
		padding-left: 34px !important

	&.hasSuffix
		padding-right: 34px !important

	&.password
		padding-right: 40px

		&.hasSuffix
			padding-right: 70px

	&.sm
		height: 33px

	&.md
		height: 70px

	&.lg
		height: 132px

	&.xl
		height: 140px


	&::placeholder
		color: $gray-light

	&:focus
		& ~ .suffix i,
		& ~ .prefix i
			color: $deep-black

			&::after
				background: $deep-black

	&:focus,
	&:hover
		//box-shadow: $shadow-secondary


.checkbox,
.radio
	position: relative

	.input
		position: absolute
		opacity: 0
		height: 0
		width: 0

	> .label
		margin-bottom: 0
		float: right

	> .input-container
		vertical-align: middle

	.checkmark
		position: relative
		background: $white
		transition: background 0.3s ease


.checkbox
	.checkmark
		border: 2px solid $gray
		height: 18px
		width: 18px

		&::before
			position: absolute
			content: ''
			display: block
			top: 50%
			left: 50%
			width: 4px
			height: 9px
			border-style: solid
			border-color: $deep-black
			border-width: 0 2px 2px 0
			transform: translate(-50%, -60%) rotate(45deg)
			opacity: 0
			transition: opacity 0.3s ease

		&.checkbox-secondary
			border: none
			//background: $gray-background

			&::before
				border-width: 0 1px 1px 0

	.input
		&:checked ~ .checkmark
			&::before
				opacity: 1


.radio
	.checkmark
		border-radius: 50%
		border: 2px solid $gray
		overflow: hidden
		display: flex
		align-items: center
		justify-content: center
		height: 25px
		width: 25px
		margin-right: 5px

		&::before
			content: ''
			display: block
			background: $blue
			width: 11px
			height: 11px
			box-shadow: $shadow-primary
			border-radius: 50%
			opacity: 0
			transition: opacity 0.3s ease

	.input
		&:checked ~ .checkmark
			//border-color: $deep-black

			&::before
				opacity: 1


.color-blue
	color: $blue


.size-sm
	width: 114px

.size-md
	width: 245px


@keyframes animateError
	0%
		opacity: 0

	100%
		opacity: 1
