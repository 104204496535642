@import 'src/styles/variables'

//height
.h-100
	height: 100%

//z-index
.z-index-1
	z-index: 1

.section
	padding: 20px 0 0
	@media (min-width: $xl)
		padding: 50px 0 20px

	&.section-small
		padding-top: 20px

	&.section-first
		padding-top: 100px
		margin-bottom: 0
		@media (min-width: $xl)
			padding-top: 150px
			margin-bottom: -110px

	&.section-last
		padding-bottom: 30px
		@media (min-width: $xl)
			padding-bottom: 50px

.container
	width: 100%
	padding-right: 15px
	padding-left: 15px
	margin-right: auto
	margin-left: auto

	&.container-fluid
		padding-right: 0
		padding-left: 0

	@media (min-width: $sm)
		max-width: 540px
	@media (min-width: $md)
		max-width: 720px
		padding-right: 30px
		padding-left: 30px
	@media (min-width: $lg)
		max-width: 960px
	@media (min-width: $xl)
		max-width: 1040px
	@media (min-width: $xxl)
		max-width: 1300px
	@media (min-width: $xxxl)
		max-width: 1494px

.align-items-center
	align-items: center

.align-items-start
	align-items: flex-start

.align-items-end
	align-items: flex-end

.justify-content-center
	justify-content: center

.justify-content-start
	justify-content: flex-start

.justify-content-end
	justify-content: flex-end

.row
	display: flex
	flex-wrap: wrap
	margin-right: -15px
	margin-left: -15px

	&.row-10 > *
		margin-bottom: 10px

	&.row-20 > *
		margin-bottom: 20px

	&.row-30 > *
		margin-bottom: 30px

	&.row-small
		margin-right: -5px
		margin-left: -5px

		[class*="col-"]
			display: flex
			padding-right: 5px
			padding-left: 5px

		@media (min-width: $xl)
			margin-right: -10px
			margin-left: -10px

			[class*="col-"]
				display: flex
				padding-right: 10px
				padding-left: 10px

	@media (min-width: $md)
		&.row-md-20 > *
			margin-bottom: 20px

	@media (min-width: $xl)
		&.row-xl-20 > *
			margin-bottom: 20px

//col offset order
[class*="col-"]
	position: relative
	width: 100%
	padding-right: 15px
	padding-left: 15px

.col
	flex-basis: 0
	flex-grow: 1
	max-width: 100%

.col-auto
	flex: 0 0 auto
	width: auto
	max-width: none

.col-1
	flex: 0 0 8.33333%
	max-width: 8.33333%

.col-2
	flex: 0 0 16.66667%
	max-width: 16.66667%

.col-3
	flex: 0 0 25%
	max-width: 25%

.col-4
	flex: 0 0 33.33333%
	max-width: 33.33333%

.col-5
	flex: 0 0 41.66667%
	max-width: 41.66667%

.col-6
	flex: 0 0 50%
	max-width: 50%

.col-7
	flex: 0 0 58.33333%
	max-width: 58.33333%

.col-8
	flex: 0 0 66.66667%
	max-width: 66.66667%

.col-9
	flex: 0 0 75%
	max-width: 75%

.col-10
	flex: 0 0 83.33333%
	max-width: 83.33333%

.col-11
	flex: 0 0 91.66667%
	max-width: 91.66667%

.col-12
	flex: 0 0 100%
	max-width: 100%

.order-first
	order: -1

.order-last
	order: 13

.order-0
	order: 0

.order-1
	order: 1

.order-2
	order: 2

.order-3
	order: 3

.order-4
	order: 4

.order-5
	order: 5

.order-6
	order: 6

.order-7
	order: 7

.order-8
	order: 8

.order-9
	order: 9

.order-10
	order: 10

.order-11
	order: 11

.order-12
	order: 12

.offset-1
	margin-left: 8.33333%

.offset-2
	margin-left: 16.66667%

.offset-3
	margin-left: 25%

.offset-4
	margin-left: 33.33333%

.offset-5
	margin-left: 41.66667%

.offset-6
	margin-left: 50%

.offset-7
	margin-left: 58.33333%

.offset-8
	margin-left: 66.66667%

.offset-9
	margin-left: 75%

.offset-10
	margin-left: 83.33333%

.offset-11
	margin-left: 91.66667%

.offset-top-10
	margin-top: 10px

.offset-top-15
	margin-top: 15px

.offset-top-20
	margin-top: 20px

.offset-top-25
	margin-top: 25px

.offset-top-30
	margin-top: 30px

.offset-top-40
	margin-top: 40px

.offset-top-50
	margin-top: 50px

.offset-top-180
	margin-top: 180px

@media (min-width: $sm)
	.col-sm
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-sm-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-sm-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-sm-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-sm-3
		flex: 0 0 25%
		max-width: 25%
	.col-sm-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-sm-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-sm-6
		flex: 0 0 50%
		max-width: 50%
	.col-sm-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-sm-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-sm-9
		flex: 0 0 75%
		max-width: 75%
	.col-sm-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-sm-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-sm-12
		flex: 0 0 100%
		max-width: 100%

	.order-sm-first
		order: -1
	.order-sm-last
		order: 13
	.order-sm-0
		order: 0
	.order-sm-1
		order: 1
	.order-sm-2
		order: 2
	.order-sm-3
		order: 3
	.order-sm-4
		order: 4
	.order-sm-5
		order: 5
	.order-sm-6
		order: 6
	.order-sm-7
		order: 7
	.order-sm-8
		order: 8
	.order-sm-9
		order: 9
	.order-sm-10
		order: 10
	.order-sm-11
		order: 11
	.order-sm-12
		order: 12

	.offset-sm-0
		margin-left: 0
	.offset-sm-1
		margin-left: 8.33333%
	.offset-sm-2
		margin-left: 16.66667%
	.offset-sm-3
		margin-left: 25%
	.offset-sm-4
		margin-left: 33.33333%
	.offset-sm-5
		margin-left: 41.66667%
	.offset-sm-6
		margin-left: 50%
	.offset-sm-7
		margin-left: 58.33333%
	.offset-sm-8
		margin-left: 66.66667%
	.offset-sm-9
		margin-left: 75%
	.offset-sm-10
		margin-left: 83.33333%
	.offset-sm-11
		margin-left: 91.66667%

@media (min-width: $md)
	.col-md
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-md-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-md-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-md-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-md-3
		flex: 0 0 25%
		max-width: 25%
	.col-md-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-md-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-md-6
		flex: 0 0 50%
		max-width: 50%
	.col-md-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-md-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-md-9
		flex: 0 0 75%
		max-width: 75%
	.col-md-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-md-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-md-12
		flex: 0 0 100%
		max-width: 100%

	.order-md-first
		order: -1
	.order-md-last
		order: 13
	.order-md-0
		order: 0
	.order-md-1
		order: 1
	.order-md-2
		order: 2
	.order-md-3
		order: 3
	.order-md-4
		order: 4
	.order-md-5
		order: 5
	.order-md-6
		order: 6
	.order-md-7
		order: 7
	.order-md-8
		order: 8
	.order-md-9
		order: 9
	.order-md-10
		order: 10
	.order-md-11
		order: 11
	.order-md-12
		order: 12

	.offset-md-0
		margin-left: 0
	.offset-md-1
		margin-left: 8.33333%
	.offset-md-2
		margin-left: 16.66667%
	.offset-md-3
		margin-left: 25%
	.offset-md-4
		margin-left: 33.33333%
	.offset-md-5
		margin-left: 41.66667%
	.offset-md-6
		margin-left: 50%
	.offset-md-7
		margin-left: 58.33333%
	.offset-md-8
		margin-left: 66.66667%
	.offset-md-9
		margin-left: 75%
	.offset-md-10
		margin-left: 83.33333%
	.offset-md-11
		margin-left: 91.66667%

@media (min-width: $lg)
	.col-lg
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-lg-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-lg-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-lg-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-lg-3
		flex: 0 0 25%
		max-width: 25%
	.col-lg-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-lg-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-lg-6
		flex: 0 0 50%
		max-width: 50%
	.col-lg-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-lg-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-lg-9
		flex: 0 0 75%
		max-width: 75%
	.col-lg-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-lg-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-lg-12
		flex: 0 0 100%
		max-width: 100%

	.order-lg-first
		order: -1
	.order-lg-last
		order: 13
	.order-lg-0
		order: 0
	.order-lg-1
		order: 1
	.order-lg-2
		order: 2
	.order-lg-3
		order: 3
	.order-lg-4
		order: 4
	.order-lg-5
		order: 5
	.order-lg-6
		order: 6
	.order-lg-7
		order: 7
	.order-lg-8
		order: 8
	.order-lg-9
		order: 9
	.order-lg-10
		order: 10
	.order-lg-11
		order: 11
	.order-lg-12
		order: 12

	.offset-lg-0
		margin-left: 0
	.offset-lg-1
		margin-left: 8.33333%
	.offset-lg-2
		margin-left: 16.66667%
	.offset-lg-3
		margin-left: 25%
	.offset-lg-4
		margin-left: 33.33333%
	.offset-lg-5
		margin-left: 41.66667%
	.offset-lg-6
		margin-left: 50%
	.offset-lg-7
		margin-left: 58.33333%
	.offset-lg-8
		margin-left: 66.66667%
	.offset-lg-9
		margin-left: 75%
	.offset-lg-10
		margin-left: 83.33333%
	.offset-lg-11
		margin-left: 91.66667%

@media (min-width: $xl)
	.col-xl
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-xl-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-xl-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-xl-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-xl-3
		flex: 0 0 25%
		max-width: 25%
	.col-xl-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-xl-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-xl-6
		flex: 0 0 50%
		max-width: 50%
	.col-xl-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-xl-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-xl-9
		flex: 0 0 75%
		max-width: 75%
	.col-xl-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-xl-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-xl-12
		flex: 0 0 100%
		max-width: 100%

	.order-xl-first
		order: -1
	.order-xl-last
		order: 13
	.order-xl-0
		order: 0
	.order-xl-1
		order: 1
	.order-xl-2
		order: 2
	.order-xl-3
		order: 3
	.order-xl-4
		order: 4
	.order-xl-5
		order: 5
	.order-xl-6
		order: 6
	.order-xl-7
		order: 7
	.order-xl-8
		order: 8
	.order-xl-9
		order: 9
	.order-xl-10
		order: 10
	.order-xl-11
		order: 11
	.order-xl-12
		order: 12

	.offset-xl-0
		margin-left: 0
	.offset-xl-1
		margin-left: 8.33333%
	.offset-xl-2
		margin-left: 16.66667%
	.offset-xl-3
		margin-left: 25%
	.offset-xl-4
		margin-left: 33.33333%
	.offset-xl-5
		margin-left: 41.66667%
	.offset-xl-6
		margin-left: 50%
	.offset-xl-7
		margin-left: 58.33333%
	.offset-xl-8
		margin-left: 66.66667%
	.offset-xl-9
		margin-left: 75%
	.offset-xl-10
		margin-left: 83.33333%
	.offset-xl-11
		margin-left: 91.66667%
	.offset-xl-top-10
		margin-top: 10px
	.offset-xl-top-20
		margin-top: 20px
	.offset-xl-top-30
		margin-top: 30px
	.offset-xl-top-50
		margin-top: 50px
	.offset-xl-top-180
		margin-top: 180px

@media (min-width: $xxl)
	.col-xxl
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-xxl-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-xxl-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-xxl-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-xxl-3
		flex: 0 0 25%
		max-width: 25%
	.col-xxl-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-xxl-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-xxl-6
		flex: 0 0 50%
		max-width: 50%
	.col-xxl-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-xxl-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-xxl-9
		flex: 0 0 75%
		max-width: 75%
	.col-xxl-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-xxl-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-xxl-12
		flex: 0 0 100%
		max-width: 100%

	.order-xxl-first
		order: -1
	.order-xxl-last
		order: 13
	.order-xxl-0
		order: 0
	.order-xxl-1
		order: 1
	.order-xxl-2
		order: 2
	.order-xxl-3
		order: 3
	.order-xxl-4
		order: 4
	.order-xxl-5
		order: 5
	.order-xxl-6
		order: 6
	.order-xxl-7
		order: 7
	.order-xxl-8
		order: 8
	.order-xxl-9
		order: 9
	.order-xxl-10
		order: 10
	.order-xxl-11
		order: 11
	.order-xxl-12
		order: 12

	.offset-xxl-0
		margin-left: 0
	.offset-xxl-1
		margin-left: 8.33333%
	.offset-xxl-2
		margin-left: 16.66667%
	.offset-xxl-3
		margin-left: 25%
	.offset-xxl-4
		margin-left: 33.33333%
	.offset-xxl-5
		margin-left: 41.66667%
	.offset-xxl-6
		margin-left: 50%
	.offset-xxl-7
		margin-left: 58.33333%
	.offset-xxl-8
		margin-left: 66.66667%
	.offset-xxl-9
		margin-left: 75%
	.offset-xxl-10
		margin-left: 83.33333%
	.offset-xxl-11
		margin-left: 91.66667%

@media (min-width: $xxxl)
	.col-xxxl
		flex-basis: 0
		flex-grow: 1
		max-width: 100%
	.col-xxxl-auto
		flex: 0 0 auto
		width: auto
		max-width: none
	.col-xxxl-1
		flex: 0 0 8.33333%
		max-width: 8.33333%
	.col-xxxl-2
		flex: 0 0 16.66667%
		max-width: 16.66667%
	.col-xxxl-3
		flex: 0 0 25%
		max-width: 25%
	.col-xxxl-4
		flex: 0 0 33.33333%
		max-width: 33.33333%
	.col-xxxl-5
		flex: 0 0 41.66667%
		max-width: 41.66667%
	.col-xxxl-6
		flex: 0 0 50%
		max-width: 50%
	.col-xxxl-7
		flex: 0 0 58.33333%
		max-width: 58.33333%
	.col-xxxl-8
		flex: 0 0 66.66667%
		max-width: 66.66667%
	.col-xxxl-9
		flex: 0 0 75%
		max-width: 75%
	.col-xxxl-10
		flex: 0 0 83.33333%
		max-width: 83.33333%
	.col-xxxl-11
		flex: 0 0 91.66667%
		max-width: 91.66667%
	.col-xxxl-12
		flex: 0 0 100%
		max-width: 100%

	.order-xxxl-first
		order: -1
	.order-xxxl-last
		order: 13
	.order-xxxl-0
		order: 0
	.order-xxxl-1
		order: 1
	.order-xxxl-2
		order: 2
	.order-xxxl-3
		order: 3
	.order-xxxl-4
		order: 4
	.order-xxxl-5
		order: 5
	.order-xxxl-6
		order: 6
	.order-xxxl-7
		order: 7
	.order-xxxl-8
		order: 8
	.order-xxxl-9
		order: 9
	.order-xxxl-10
		order: 10
	.order-xxxl-11
		order: 11
	.order-xxxl-12
		order: 12

	.offset-xxxl-0
		margin-left: 0
	.offset-xxxl-1
		margin-left: 8.33333%
	.offset-xxxl-2
		margin-left: 16.66667%
	.offset-xxxl-3
		margin-left: 25%
	.offset-xxxl-4
		margin-left: 33.33333%
	.offset-xxxl-5
		margin-left: 41.66667%
	.offset-xxxl-6
		margin-left: 50%
	.offset-xxxl-7
		margin-left: 58.33333%
	.offset-xxxl-8
		margin-left: 66.66667%
	.offset-xxxl-9
		margin-left: 75%
	.offset-xxxl-10
		margin-left: 83.33333%
	.offset-xxxl-11
		margin-left: 91.66667%
