@import 'src/styles/variables'

.advantages
	&__cart
		background: $white
		box-shadow: 0 4px 8px rgba(175, 175, 175, 0.25)
		display: flex
		align-items: center
		flex-direction: column
		padding: 0 3px 24px
		border-radius: 20px

		img
			max-width: 100%

		&.interface, &.delivery
			padding-top: 10px
			min-height: 475px

			.advantages__cart-text
				margin-top: 30px

		&.interface
			justify-content: space-between
			@media (min-width: $xxxl)
				border-radius: 20px 0 0 20px

		&.delivery
			justify-content: flex-end
			@media (min-width: $xxxl)
				border-radius: 0 20px 20px 0

		&.products, &.sale, &.support
			width: 100%
			height: 242px
			margin-left: 15px
			margin-right: 15px
			@media (min-width: $lg)
				width: 46.7%
				height: 279px
			@media (min-width: $xxl)
				width: 47.63%
			@media (min-width: $xxxl)
				width: 236px
				height: 242px

		&.products
			justify-content: flex-end
			@media (min-width: $xxxl)
				border-radius: 20px 20px 0 20px
				margin-left: 52px

			.advantages__cart-text
				margin-top: 30px

		&.sale
			justify-content: flex-end
			@media (min-width: $xxxl)
				border-radius: 20px 20px 20px 0

			.advantages__cart-text
				margin-top: 18px

		&.payment
			justify-content: flex-end
			width: 100%
			height: 280px
			margin-left: 15px
			margin-right: 15px
			@media (min-width: $lg)
				width: 46.7%
			@media (min-width: $xxl)
				width: 47.63%
			@media (min-width: $xxxl)
				border-radius: 20px 0 20px 20px
				width: 273px
				height: 279px

			.advantages__cart-text
				margin-top: 18px

		&.support
			justify-content: flex-end
			@media (min-width: $xxxl)
				border-radius: 0 20px 20px 20px

		&.stores, &.economy
			padding-left: 10px
			padding-right: 10px
			height: 242px
			@media (min-width: $lg)
				height: 279px
			@media (min-width: $xxl)
				height: 242px

		&.stores
			justify-content: flex-end

			.advantages__cart-text
				margin-top: 50px
				@media (min-width: $xxl)
					margin-top: 10px

		&.economy
			padding-bottom: 0
			justify-content: center
			@media (min-width: $sm)
				padding-bottom: 24px
				justify-content: flex-end
			@media (min-width: $xxl)
				padding-bottom: 0
				justify-content: center

			.advantages__cart-text
				margin-top: 30px
				max-width: 291px
				@media (min-width: $sm)
					position: absolute
					bottom: 30px
					left: 30.5%
				@media (min-width: $md)
					left: 35.5%
				@media (min-width: $lg)
					left: 27.5%
				@media (min-width: $xxl)
					left: 35.5%
				@media (min-width: $xxxl)
					bottom: 24px
					left: 35.5%

	.sections
		display: flex
		flex-wrap: wrap
		margin-left: -15px
		margin-right: -15px
		margin-bottom: -30px
		@media (min-width: $xxxl)
			margin-top: -75px

		.advantages__cart
			margin-bottom: 30px
