@import 'variables'

html, body
	font-family: $font-primary
	color: $deep-black
	font-size: 12px
	line-height: 20px
	font-weight: 700
	background-color: #F5F9FB
	@media (min-width: $md)
		font-size: 14px

body
	overflow-y: scroll


*
	box-sizing: border-box
	margin: 0
	padding: 0


a
	color: inherit
	text-decoration: none


button
	outline: none
	border: none
	background: none


ul
	list-style: none


::-webkit-scrollbar-track
	-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 20%)
	background-color: $white

::-webkit-scrollbar-thumb
	background-color: $blue

::-webkit-scrollbar
	width: 4px
	height: 4px
	background-color: $white


input[type="number"]
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		-webkit-appearance: none
