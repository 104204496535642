@import 'src/styles/variables'

.select-location
	background: $white
	padding: 20px
	visibility: hidden

	&.active
		visibility: visible

	.search
		padding-top: 10px

	.determine-location
		margin-top: 15px
		color: $gray-light

	.close
		position: absolute
		top: 20px
		right: 20px
		z-index: 999

	&.mobile
		padding: 0

		.search
			padding-top: 0
			padding-right: 40px

		.determine-location
			position: fixed
			bottom: 20px
			height: 37px
			text-align: center
			background: $blue
			width: 90%
			right: 50%
			transform: translateX(50%)
			display: flex
			align-items: center
			justify-content: center
			box-shadow: 0 3.42735px 51.4103px rgba(20, 0, 79, 0.1)
			border-radius: 5px
			color: $white
			@media (min-width: 700px)
				width: 466px
				right: auto
				transform: none

		.close
			top: 25px


.delete-img
	img
		object-fit: contain
		width: 100%
		height: 100%
