@import 'src/styles/variables'

.locale-switcher
	display: flex
	justify-content: space-between
	align-items: center
	padding-left: 11px
	height: 49px
	width: 107px
	background: #F4F4F4
	box-shadow: inset 0px 0px 4px rgba(131, 129, 129, 0.25)
	border-radius: 300px
	position: relative

	.locale
		cursor: pointer
		font-family: 'Montserrat'
		font-style: normal
		font-weight: 400
		font-size: 18px
		line-height: 22px

		span
			color: black

		&:not(:last-child)
			margin-right: 15px


.locround
	position: absolute
	top: 11%
	background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
	box-shadow: 0px 0px 4px rgba(8, 31, 67, 0.63)
	border-radius: 50%
	width: 39px
	height: 39px
	transition: 0.3s
