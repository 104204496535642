@import 'src/styles/variables'
@import 'src/styles/animations'

.button
	height: 48px
	font-family: $font-primary
	font-size: 18px
	font-weight: 700
	line-height: 24px
	cursor: pointer
	transition: all 200ms linear
	position: relative
	padding: 5px
	display: inline-flex
	align-items: center
	justify-content: center
	text-align: center
	align-self: center
	border: none
	outline: 0
	color: $white
	border-radius: 10px
	@media (min-width: $xl)
		padding: 10px

	&:disabled
		cursor: default
		box-shadow: none
		background: $gray

		&:hover
			background: $gray


.primary
	background: $blue
	border: 2px solid $blue

	&:hover, &:active
		background: $blue-dark
		border: 2px solid $blue-dark

.secondary
	background: $white
	border: 2px solid $blue
	color: $blue
	padding: 10px 30px

	&:hover, &:active
		box-shadow: 0 0 10px $gray-light

.client
	box-shadow: 0 9px 31px rgba(255, 255, 255, 0.58)
	border-radius: 113px
	width: 327px
	height: 74px
	background: $white
	color: $blue
	font-size: 20px
	justify-content: flex-start
	padding-left: 40px
	transition: .3s ease-in-out

	&:hover
		box-shadow: 0 5px 41px rgba(255, 255, 255, 0.88)

	&:before
		content: ''
		position: absolute
		width: 57px
		height: 57px
		right: 10px
		border-radius: 50%
		background: $blue
		box-shadow: 0 2px 23px rgba(66, 142, 254, 0.51)

	&:after
		content: url('/assets/index/arrow-right.svg')
		position: absolute
		width: 57px
		right: 10px
		padding-top: 5px
		animation: cloud 3s ease-in-out infinite

.search
	width: 174px
	height: 100%
	background: $blue-light
	box-shadow: inset 0px -4px 4px rgba(3, 0, 40, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.13)
	border-radius: 5px
	padding: 5px 8px

.sm
	width: 140px

.md
	min-width: 100px
	@media (min-width: $xl)
		width: 170px

.lg
	width: 230px

.isFluid
	width: 100%


.button-group
	display: flex
	margin-left: -5px
	margin-right: -5px
	@media (min-width: $xl)
		margin-left: -10px
		margin-right: -10px

	.button
		margin-left: 5px
		margin-right: 5px
		@media (min-width: $xl)
			margin-left: 10px
			margin-right: 10px

	&.column
		flex-direction: column
		margin-left: 0
		margin-right: 0

		button
			&:not(:last-child)
				margin-bottom: 10px
