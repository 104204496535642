@import 'src/styles/variables'
@import 'src/styles/animations'

.people-wrapper
	display: flex
	@media (min-width: $xl)
		position: absolute
		right: 0
		bottom: 0


.people-container
	position: relative
	margin-left: auto
	margin-right: auto
	@media (min-width: $xl)
		right: 20px
		bottom: 8px

.people
	height: auto
	max-width: 798px
	width: 100%
	@media (min-width: $xxl)
		width: calc(50vw - 160px)

.question
	position: absolute
	max-width: 7%
	animation: jump 2s ease infinite
	@media (min-width: $xxl)
		max-width: initial

	&.blue
		top: 13%
		left: 8.5%

	&.orange-small
		top: 14.5%
		left: 27%
		animation-duration: 5s

	&.green
		top: 16%
		left: 45.5%
		animation-duration: 3s

	&.orange
		top: 21%
		left: 68.5%
		animation-duration: 4s

	&.red
		top: 0
		left: 76.5%
		animation-duration: 5s

