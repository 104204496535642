@import 'src/styles/variables'

.store
	width: 100%
	max-width: 252px
	display: flex
	flex-direction: column
	justify-content: space-between
	position: relative
	filter: drop-shadow(0 0 10px rgba(128, 128, 128, 0.3))
	border: 6px solid #124F6A
	border-top-width: 10px
	border-bottom-width: 10px
	border-radius: 15px
	background: white
	min-height: 380px

	&__header
		position: relative
		width: 110%
		left: 50%
		transform: translateX(-50%)

		img
			object-fit: cover
			height: 100%
			width: 100%

	&__content
		height: 100%
		padding: 0 3px 10px
		display: flex
		flex-direction: column
		justify-content: space-between
		@media (min-width: $xl)
			padding: 3px

		.logo
			width: 49px
			height: 45px
			background: white
			// border: 1px solid black
			display: flex
			align-items: center
			justify-content: center
			text-align: center
			margin-left: auto
			margin-right: auto
			@media (min-width: $xl)
				margin-top: 17px

		.store-name
			margin-top: 15px
			// height: 60px
			-webkit-line-clamp: 3
			display: -webkit-box
			-webkit-box-orient: vertical
			// overflow: hidden
			padding-bottom: 10px
			@media (min-width: $xl)
				margin-top: 3px
				-webkit-line-clamp: 2
				// height: 50px

			p
				font-size: 14px
				line-height: 17px
				@media (min-width: $xl)
					font-size: 15px
					line-height: 19px

		.store-info
			display: flex

			&:not(:last-child)
				margin-bottom: 10px

			&.working-hours
				position: relative

			&__door
				margin-left: auto
				position: absolute
				right: -10px
				bottom: -8px
				@media (min-width: $xl)
					position: static

				img
					width: 80%
					@media (min-width: $xl)
						width: 100%

			&__working-mode
				p
					span
						display: block
						@media (min-width: $xl)
							display: initial

			p
				font-size: 12px
				line-height: 15px
				@media (min-width: $xl)
					font-size: 12px
					line-height: 17px

			i
				margin-right: 3px

	&__footer
		img
			max-width: 100%
			vertical-align: top
