@import 'src/styles/variables'

.text-xl
	font-size: 22px
	line-height: 27px

.text-lg
	font-size: 20px
	line-height: 24px

.text-md
	font-size: 18px
	line-height: 25px

.text-sm
	font-size: 16px
	line-height: 19px

.text-xs
	font-size: 14px
	line-height: 17px


.color-primary
	color: $deep-black

.color-gray
	color: $gray

.color-blue
	color: $blue

.color-white
	color: $white

.color-blue-dark
	color: $blue-dark

.color-gray-light
	color: $gray-light


.text-start
	text-align: left

.text-end
	text-align: right

.text-center
	text-align: center

.text-justify
	text-align: justify


.text-light
	font-weight: 300

.text-regular
	font-weight: 400

.text-medium
	font-weight: 500

.text-bold
	font-weight: 700


.text-overflow-ellipsis
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	max-width: 100%

.text-overflow-fade
	position: relative
	display: block
	overflow: hidden
	white-space: nowrap
	max-width: 100%

	&::after
		position: absolute
		right: 0
		top: 0
		bottom: 0
		content: ''
		width: 2em
		background-image: linear-gradient(270deg, black 35%, transparent)


.text-transform-lowercase
	text-transform: lowercase

.text-transform-uppercase
	text-transform: uppercase

.text-transform-capitalize
	text-transform: capitalize


.text-decoration-underline
	text-decoration: underline

.text-decoration-line-through
	text-decoration: line-through
	text-decoration-color: $deep-black
	text-decoration-thickness: 2px


.white-space-nowrap
	white-space: nowrap

.white-space-pre-line
	white-space: pre-line


.cursor-pointer
	cursor: pointer
